<template>
    <div style="padding: 0px;" class="flex">
        <div class="col-xs-3 h100">
            <h2>Подземелья</h2>
            <div class="item-list">
                <div v-if="!instances || (instances && !instances.length)">Подземелий нету</div>
                <ul v-if="instances && instances.length">
                    <li
                        v-for="item of instances"
                        :style="{'background-color': (models.instance.select != item) ? '':'rgba(255,160,0,0.5)'}"
                        :subtitle="`Рейдовое подземелье`"
                        :title="`${item.name}`"
                        @click="models.instance.select = item; 
                        models.boss.select = null; 
                        models.itemset.select = null; 
                        models.item.select = null; 
                        itemsets = null;
                        items = null;
                        refresh_bosses();"
                    >
                        {{ item.name }}
                    </li>
                </ul>
                
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" text="Добавить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Добавить подземелье">
                            <v-card-text>
                                Укажите название добавляемого подземелья
                                <v-text-field v-model="models.instance.create.name" label="Название подземелья"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="instance_create(), isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" :disabled="!models.instance.select" v-bind="activatorProps" text="Редактировать"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Редактировать подземелье">
                            <v-card-text>
                                Укажите новое название подземелья
                                <v-text-field v-model="models.instance.select.name" label="Название подземелья"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="instance_update(), isActive.value = false" text="Сохранить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" :disabled="!models.instance.select" text="Удалить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Удалить подземелье">
                            <v-card-text>
                                Вы уверены что хотите удалить подземелье "{{models.instance.select.name}}"?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="instance_delete(), isActive.value = false" text="Удалить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </div>
        <div class="col-xs-3 h100">
            <h2>Боссы</h2>
            <div class="item-list">
                <div v-if="!bosses || (bosses && !bosses.length)">Боссов нету</div>
                <ul v-if="bosses && bosses.length">
                    <li
                        v-for="item of bosses"
                        :style="{'background-color': (models.boss.select != item) ? '':'rgba(255,160,0,0.5)'}"
                        :subtitle="`Рейдовое подземелье`"
                        :title="`${item.name}`"
                        @click="models.boss.select = item; models.itemset.select = null; models.item.select = null; refresh_itemsets(); items = null;"
                    >
                        {{ item.name }}
                    </li>
                </ul>
                
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" :disabled="!models.instance.select" v-bind="activatorProps" text="Добавить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Добавить босса">
                            <v-card-text>
                                Укажите имя добавляемого в "{{ models.instance.select.name }}" босса
                                <v-text-field v-model="models.boss.create.name" label="Имя босса"></v-text-field>
                                <v-text-field v-model="models.boss.create.priority" type="number" step="0.01" label="Приоритет"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="boss_create(), isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" :disabled="!models.boss.select" v-bind="activatorProps" text="Редактировать"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Редактировать босса">
                            <v-card-text>
                                Укажите новое имя босса
                                <v-text-field v-model="models.boss.select.name" label="Имя босса"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="boss_update(), isActive.value = false" text="Сохранить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <!-- <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" :disabled="!models.boss.select" v-bind="activatorProps" text="Копировать"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Копировать босса">
                            <v-card-text>
                                Укажите имя добавляемого в "{{ models.instance.select.name }}" босса на основе босса "{{ models.boss.select.name }}"<br>
                                Новому боссу будут скопированы его группы предметов и содержащиеся в них предметы.
                                <v-text-field v-model="models.boss.create.name" label="Имя босса"></v-text-field>
                                <v-text-field v-model="models.boss.create.priority" type="number" step="0.01" label="Приоритет"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="boss_create(), isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog> -->
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" :disabled="!models.boss.select" text="Удалить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Удалить босса">
                            <v-card-text>
                                Вы уверены что хотите удалить босса "{{models.boss.select.name}}"?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="null, isActive.value = false" text="Удалить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </div>
        <div class="col-xs-3 h100">
            <h2>Группы предметов</h2>
            <div class="item-list">
                <div v-if="!itemsets || (itemsets && !itemsets.length)">Групп предметов нет</div>
                <ul v-if="itemsets && itemsets.length">
                    <li
                        v-for="item of itemsets"
                        :style="{'background-color': (models.itemset.select != item) ? '':'rgba(255,160,0,0.5)'}"
                        :subtitle="`Рейдовое подземелье`"
                        :title="`${item.name}`"
                        @click="models.itemset.select = item; models.item.select = null; refresh_items()"
                    >
                        {{ item.name }} - {{ item.chance * 100 }}%
                    </li>
                </ul>
                
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn :disabled="!models.boss.select" class="btn-warcraft" v-bind="activatorProps" text="Добавить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Добавить группу предметов">
                            <v-card-text>
                                Укажите название добавляемой группы предметов
                                <v-text-field v-model="models.itemset.create.name" label="Название группы предметов"></v-text-field>
                                <v-text-field v-model="models.itemset.create.chance" label="Шанс в % (1-100)" min="0" max="100" step="1" type="number"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="itemset_create(), isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn :disabled="!models.itemset.select" class="btn-warcraft" v-bind="activatorProps" text="Редактировать"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Редактировать группу предметов">
                            <v-card-text>
                                Укажите данные изменяемой группы предметов
                                <v-text-field v-model="models.itemset.select.name" label="Название группы предметов"></v-text-field>
                                <v-text-field v-model="models.itemset.select.chance" label="Шанс в % (1-100)" min="0" max="100" step="1" type="number"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="itemset_update(); isActive.value = false" text="Сохранить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" :disabled="!models.itemset.select" text="Удалить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Удалить группу предметов">
                            <v-card-text>
                                Вы уверены что хотите удалить группу предметов "{{models.itemset.select.name}}"?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="itemset_delete(); isActive.value = false" text="Удалить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </div>
        <div class="col-xs-3 h100">
            <h2>Предметы</h2>
            <div class="item-list">
                <div v-if="!items || (items && !items.length)">Предметов нету</div>
                <ul v-if="items && items.length">
                    <v-tooltip v-for="item of items">
                        <template v-slot:default>
                            <div class="d-flex">
                                <div class="mr-1"><v-img width="38pt" height="38pt" :src="item.json_data.icon"></v-img></div>
                                <div id="s-wow-tooltip" class='active' ref="tooltip" > <!-- v-show="tooltip_show" -->
                                    <div style="font-size: 14px;" :class="`q${item.json_data.quality}`">
                                        {{  item.json_data.name }}
                                    </div>
                                    <div v-if="item.json_data.is_heroic" style="color: #1eff00;">Героический</div>
                                    <div v-if="item.json_data.bonding">
                                        <span v-if="item.json_data.bonding == 1">Становится персональным при получении</span>
                                        <span v-if="item.json_data.bonding == 2">Становится персональным при надевании</span>
                                    </div>
                                    <div class="d-flex">
                                        <div>{{ subClasses[item.json_data.class][item.json_data.subclass] }}</div>
                                        <v-spacer></v-spacer>
                                        <div>{{ subClasses[item.json_data.class][item.json_data.subclass] == inventoryType[item.json_data.inventory_type] ? '': inventoryType[item.json_data.inventory_type]}}</div>
                                    </div>
                                    <div class="d-flex" v-if="item.json_data.dmg_min1 || item.json_data.dmg_max1">
                                        <div>Урон: {{ item.json_data.dmg_min1 }} - {{ item.json_data.dmg_max1 }}</div>
                                        <v-spacer></v-spacer>
                                        <div>Скорость: {{ (item.json_data.delay * 0.001).toFixed(2) }}</div>
                                    </div>
                                    <div v-if="item.json_data.dps">({{ item.json_data.dps.toFixed(2) }} урона в секунду)</div>
                                    <div v-if="item.json_data.armor">Броня: {{ item.json_data.armor }}</div>
                                    <div v-if="item.json_data.stat_type1 && item.json_data.stat_type1 < 12">{{ item.json_data.stat_value1 > 0 ? `+${item.json_data.stat_value1}`: `-${item.json_data.stat_value1}` }} {{ statType[item.json_data.stat_type1] }}</div>
                                    <div v-if="item.json_data.stat_type2 && item.json_data.stat_type2 < 12">{{ item.json_data.stat_value2 > 0 ? `+${item.json_data.stat_value2}`: `-${item.json_data.stat_value2}` }} {{ statType[item.json_data.stat_type2] }}</div>
                                    <div v-if="item.json_data.stat_type3 && item.json_data.stat_type3 < 12">{{ item.json_data.stat_value3 > 0 ? `+${item.json_data.stat_value3}`: `-${item.json_data.stat_value3}` }} {{ statType[item.json_data.stat_type3] }}</div>
                                    <div v-if="item.json_data.stat_type4 && item.json_data.stat_type4 < 12">{{ item.json_data.stat_value4 > 0 ? `+${item.json_data.stat_value4}`: `-${item.json_data.stat_value4}` }} {{ statType[item.json_data.stat_type4] }}</div>
                                    <div v-if="item.json_data.stat_type5 && item.json_data.stat_type5 < 12">{{ item.json_data.stat_value5 > 0 ? `+${item.json_data.stat_value5}`: `-${item.json_data.stat_value5}` }} {{ statType[item.json_data.stat_type5] }}</div>
                                    <div v-if="item.json_data.stat_type6 && item.json_data.stat_type6 < 12">{{ item.json_data.stat_value6 > 0 ? `+${item.json_data.stat_value6}`: `-${item.json_data.stat_value6}` }} {{ statType[item.json_data.stat_type6] }}</div>
                                    <div v-if="item.json_data.stat_type7 && item.json_data.stat_type7 < 12">{{ item.json_data.stat_value7 > 0 ? `+${item.json_data.stat_value7}`: `-${item.json_data.stat_value7}` }} {{ statType[item.json_data.stat_type7] }}</div>
                                    <div v-if="item.json_data.stat_type8 && item.json_data.stat_type8 < 12">{{ item.json_data.stat_value8 > 0 ? `+${item.json_data.stat_value8}`: `-${item.json_data.stat_value8}` }} {{ statType[item.json_data.stat_type8] }}</div>
                                    <div v-if="item.json_data.stat_type9 && item.json_data.stat_type9 < 12">{{ item.json_data.stat_value9 > 0 ? `+${item.json_data.stat_value9}`: `-${item.json_data.stat_value9}` }} {{ statType[item.json_data.stat_type9] }}</div>
                                    <div v-if="item.json_data.stat_type10 && item.json_data.stat_type10 < 12">{{ item.json_data.stat_value10 > 0 ? `+${item.json_data.stat_value10}`: `-${item.json_data.stat_value10}` }} {{ statType[item.json_data.stat_type10] }}</div>
                                    <div v-if="item.json_data.sockets.length">
                                        <div v-for="socket of item.json_data.sockets">
                                            <span :class="{
                                                'socket-meta': socket.type == 'meta',
                                                'socket-red': socket.type == 'red',
                                                'socket-blue': socket.type == 'blue',
                                                'socket-yellow': socket.type == 'yellow',
                                                'socket-prismatic': socket.type == 'prismatic',
                                                'socket-hydraulic': socket.type == 'hydraulic',
                                                'socket-cogwheel': socket.type == 'cogwheel',
                                            }" style="padding-left: 18px; color: grey;">{{ (()=>{
                                                if(socket.type == 'meta') return 'Особое гнездо';
                                                if(socket.type == 'red') return 'Красное гнездо';
                                                if(socket.type == 'blue') return 'Синее гнездо';
                                                if(socket.type == 'yellow') return 'Желтое гнездо';
                                                if(socket.type == 'prismatic') return 'Радужное гнездо';
                                            })() }}</span>
                                            
                                        </div>
                                    </div>
                                    <div v-if="item.json_data.socket_bonus_ench" style="color: grey;">При соответствии цвета: {{ item.json_data.socket_bonus_ench.name }}</div>
                                    <div v-if="item.json_data.durability">Прочность {{ item.json_data.durability }}/{{ item.json_data.durability }}</div>
                                    <div v-if="item.json_data.required_level">Требуемый уровень: {{ item.json_data.required_level }}</div>
                                    <div v-if="item.json_data.item_level" :style="{'color': 'gold'}">Уровень предмета: {{ item.json_data.item_level }}</div>
                                    <div v-if="item.json_data.required_spell">
                                        Требуется: {{ item.json_data.required_spell.name }}
                                    </div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type1 && item.json_data.stat_type1 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type1], [item.json_data.stat_value1]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type2 && item.json_data.stat_type2 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type2], [item.json_data.stat_value2]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type3 && item.json_data.stat_type3 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type3], [item.json_data.stat_value3]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type4 && item.json_data.stat_type4 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type4], [item.json_data.stat_value4]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type5 && item.json_data.stat_type5 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type5], [item.json_data.stat_value5]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type6 && item.json_data.stat_type6 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type6], [item.json_data.stat_value6]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type7 && item.json_data.stat_type7 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type7], [item.json_data.stat_value7]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type8 && item.json_data.stat_type8 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type8], [item.json_data.stat_value8]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type9 && item.json_data.stat_type9 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type9], [item.json_data.stat_value9]) }}</div>
                                    <div style="color: #1eff00;" v-if="item.json_data.stat_type10 && item.json_data.stat_type10 >= 12">Если на персонаже: {{ formatString(statType[item.json_data.stat_type10], [item.json_data.stat_value10]) }}</div>
                                    <!-- <div style="color: #1eff00;" v-if="item.json_data.spell_triggers.length" v-for="spell in item.json_data.spell_triggers">
                                        
                                        <span v-if="spell.trigger == 1">Если на персонаже: {{ spell.description }}</span>
                                        <span v-if="spell.trigger == 0">Использование: {{ spell.description }}</span>
                                    </div> -->
                                    <div style="color: gold;" v-if="item.json_data.description 
                                    && item.json_data.description.length 
                                    && (
                                        !item.json_data.description.includes('На этом оружии не отображаются иллюзии и другие визуальные эффекты.')
                                        ||
                                        !item.json_data.description.includes('Бонус отключен на аренах и полях боя.')
                                    )">{{ item.json_data.description }}</div>
                                    <div v-if="item.json_data.price && (item.json_data.price.gold > 0 || item.json_data.price.silver > 0 || item.json_data.price.copper > 0)">
                                        Цена: 
                                        <span v-if="item.json_data.price.gold" class="moneygold">{{ item.json_data.price.gold }}</span>
                                        <span v-if="item.json_data.price.silver" class="moneysilver">{{ item.json_data.price.silver }}</span>
                                        <span v-if="item.json_data.price.copper" class="moneycopper">{{ item.json_data.price.copper }}</span> 
                                    </div>

                                </div>
                            </div>
                        </template>
                        <template v-slot:activator="{props}">
                            <li
                                v-bind="props"
                                :style="{'background-color': (models.item.select != item) ? (item.blacklisted ? 'rgba(255,255,0,0.3)': 'inherit') : 'rgba(255,160,0,0.5)'}"
                                :subtitle="`Рейдовое подземелье`"
                                @click="models.item.select = item; null" ref="armoryItem"
                                @auxclick="blacklist($event, item)"
                            >
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><v-img :src="item.json_data.icon" width="24px" /></td>
                                            <td :class="`q${item.json_data.quality}`">{{ item.json_data.name }} <span v-if="item.json_data.is_heroic" style="color: green; font-size: 12px;">Героический</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                        </template>
                    </v-tooltip>
                </ul>
                
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn :disabled="!models.itemset.select" class="btn-warcraft" v-bind="activatorProps" text="Добавить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Добавить предмет">
                            <v-card-text>
                                Укажите ID добавляемого предмета или предметов через запятую
                                <v-text-field v-model="models.item.create.item_id" label="ID предмета"></v-text-field>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="item_create(); isActive.value = false" text="Добавить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <v-dialog max-width="500">
                    <template v-slot:activator="{props: activatorProps}">
                        <v-btn class="btn-warcraft" v-bind="activatorProps" :disabled="!models.item.select" text="Удалить"></v-btn>
                    </template>
                    <template v-slot:default="{isActive}">
                        <v-card title="Удалить предмет">
                            <v-card-text>
                                Вы уверены что хотите удалить предмет "{{models.item.select.json_data.name}}"?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="item_delete(), isActive.value = false" text="Удалить"></v-btn>
                                <v-btn @click="isActive.value = false">Отмена</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </div>
        </div>
    </div>
    <!-- Тултип -->
    <div v-show="showTooltip" ref="tooltip" class="tooltip" :style="{ top: `${tooltipY}px`, left: `${tooltipX}px` }">
        test
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import { mapGetters, mapMutations } from 'vuex'
import axios from 'axios'

export default {
    data(){
        return {
            showTooltip: false,
            tooltipData: null,
            tooltipX: 0,
            tooltipY: 0,
            instances: null,
            bosses: null,
            itemsets: null,
            items: null,
            models: {
                instance: {
                    select: null,
                    create: {
                        name: null,
                    }
                },
                boss: {
                    select: null,
                    create: {
                        name: null,
                        priority: 1.0
                    }
                },
                itemset: {
                    select: null,
                    create: {
                        name: null,
                        chance: 100
                    }
                },
                item: {
                    select: null,
                    create: {
                        item_id: null,
                    }
                }
            },
            statType: [
                "к мане",
                "к здоровью",
                null,
                "к ловкости",
                "к силе",
                "к интеллекту",
                "к духу",
                "к выносливости",
                null,
                null,
                null,
                null,
                "Рейтинг защиты +{0}.",
                "Рейтинг уклонения +{0}.",
                "Рейтинг парирования +{0}.",
                "Рейтинг блокирования щитом +{0}.",
                "Рейтинг меткости (оруж. ближ. боя) +{0}.",
                "Рейтинг меткости (оруж. дальн. боя) +{0}.",
                "Рейтинг меткости (заклинания) +{0}.",
                "Рейтинг крит. удара оруж. ближнего боя +{0}.",
                "Рейтинг крит. удара оруж. дальнего боя +{0}.",
                "Рейтинг критического удара (заклинания) +{0}.",
                "Рейтинг уклонения от удара оруж. ближ. боя +{0}.",
                "Рейтинг уклонения от удара оруж. дальн. боя +{0}.",
                "Рейтинг уклонения от удара (заклинания) +{0}.",
                "Рейтинг уклонения от крит. удара оруж. ближнего боя +{0}.",
                "Рейтинг уклонения от крит. удара оруж. дистанц. боя +{0}.",
                "Рейтинг уклонения от крит. удара (заклинания) +{0}.",
                "Рейтинг скорости ближнего боя +{0}.",
                "Рейтинг скорости дальнего боя +{0}.",
                "Рейтинг скорости боя (заклинания) +{0}.",
                "Рейтинг меткости +{0}.",
                "Рейтинг критического удара +{0}.",
                "Рейтинг уклонения от удара +{0}.",
                "Рейтинг уклонения от крит. удара +{0}.",
                "Рейтинг устойчивости +{0}.",
                "Рейтинг скорости +{0}.",
                "Рейтинг мастерства +{0}.",
                "Увеличивает силу атаки на {0}.",
                "Сила атаки дальнего боя +{0}.",
                "Увеличивает силу атаки на {0} в облике кошки, медведя, лютого медведя или лунного совуха.",
                "Рейтинг натиска +{0}.",
                "Увеличивает целительное действие магических заклинаний и эффектов на {0} ед.",
                "Восполнение {0} ед. маны раз в 5 секунд.",
                "Повышает рейтинг пробивания брони на {0}.",
                "Увеличивает силу заклинаний на {0}.",
                "Восполняет {0} ед. здоровья каждые 5 секунд.",
                "Увеличивает проникающую способность заклинаний на {0}.",
                "Увеличивает показатель блокирования щита на {0}.",
                "Unknown Bonus #{0} ({1})"
            ],
            inventoryType: {
                0: null,
                1: "Голова",
                2: "Шея",
                3: "Плечи",
                4: "Рубашка",
                5: "Грудь",
                6: "Пояс",
                7: "Ноги",
                8: "Ступни",
                9: "Запястья",
                10: "Кисти рук",
                11: "Палец",
                12: "Аксессуар",
                13: "Одноручное",
                14: "Левая рука",
                15: "Дальний бой",
                16: "Спина",
                17: "Двуручное",
                18: "Сумка",
                19: "Гербовая накидка",
                20: null,
                21: "Правая рука",
                22: "Левая рука",
                23: "Левая рука",
                24: "Боеприпасы",
                25: "Метательное",
                26: null,
                27: "Колчан",
                28: "Реликвия"
            },
            slots: {
                0: null,
                1: "Голова",
                2: "Шея",
                3: "Плечи",
                4: "Рубашка",
                5: "Грудь",
                6: "Пояс",
                7: "Ноги",
                8: "Ступни",
                9: "Запястья",
                10: "Перчатки",
                11: "Палец",
                12: "Аксессуар",
                13: "Одноручное",
                14: "Щит",
                15: "Дальний бой",
                16: "Спина",
                17: "Двуручное",
                18: "Сумка",
                19: "Гербовая накидка",
                20: "Грудь",
                21: "Правая рука",
                22: "Левая рука",
                23: "Левая рука",
                24: "Амуниция",
                25: "Метательное",
                26: "Дальний бой",
                27: "Колчан",
                28: "Реликвия"
            },
            subClasses: {
                0: {
                    "-3": "Временное улучшение",
                    0: "Расходуется",
                    1: "Зелье",
                    2: "Эликсир",
                    3: "Фляга",
                    4: "Свиток",
                    5: "Еда и напитки",
                    6: "Улучшение",
                    7: "Бинты",
                    8: "Разное (расходуемые)"
                },
                1: {
                    0: "Сумка",
                    1: "Сумка душ",
                    2: "Сумка травника",
                    3: "Сумка зачаровывателя",
                    4: "Сумка инженера",
                    5: "Сумка ювелира",
                    6: "Сумка шахтера",
                    7: "Сумка кожевника",
                    8: "Сумка начертателя"
                },
                2: {
                    0: "Одноручный топор",
                    1: "Двуручный топор",
                    2: "Лук",
                    3: "Огнестрельное",
                    4: "Одноручное дробящее",
                    5: "Двуручное дробящее",
                    6: "Древковое",
                    7: "Одноручный меч",
                    8: "Двуручный меч",
                    10: "Посох",
                    13: "Кистевое оружие",
                    14: "Разное",
                    15: "Кинжал",
                    16: "Метательное",
                    18: "Арбалет",
                    19: "Жезл",
                    20: "Удочка"
                },
                3: {
                    0: "Красный самоцвет",
                    1: "Синиий самоцвет",
                    2: "Желтый самоцвет",
                    3: "Фиолетовый самоцвет",
                    4: "Зеленый самоцвет",
                    5: "Оранжевый самоцвет",
                    6: "Особый самоцвет",
                    7: "Простой самоцвет",
                    8: "Радужный самоцвет"
                },
                4: {
                    "-2": "Кольцо",
                    "-3": "Амулет",
                    "-4": "Аксессуар",
                    "-5": "Левая рука",
                    "-6": "Спина",
                    "-7": "Гербовая накидка",
                    "-8": "Рубашка",
                    0: "Разное",
                    1: "Тканевые",
                    2: "Кожаные",
                    3: "Кольчужные",
                    4: "Латные",
                    6: "Щит",
                    7: "Манускрипт",
                    8: "Идол",
                    9: "Тотем",
                    10: "Печать"
                },
                6: {
                    2: "Стрелы",
                    3: "Пули"
                },
                7: {
                    1: "Деталь",
                    2: "Взрывчатка",
                    3: "Устройство",
                    4: "Ювелирное дело",
                    5: "Ткань",
                    6: "Кожа",
                    7: "Металл и камни",
                    8: "Мясо",
                    9: "Растение",
                    10: "Элементаль",
                    11: "Разное (хозяйственные товары)",
                    12: "Наложение чар",
                    13: "Материалы",
                    14: "Улучшение брони",
                    15: "Улучшение оружия"
                },
                9: {
                    0: "Книга",
                    1: "Кожевничество",
                    2: "Портняжное дело",
                    3: "Инженерное дело",
                    4: "Кузнечное дело",
                    5: "Кулинария",
                    6: "Алхимия",
                    7: "Первая помощь",
                    8: "Зачаровывание",
                    9: "Рыбная ловля",
                    10: "Ювелирное дело",
                    11: "Технология Начертания"
                },
                11: {
                    2: "Колчан",
                    3: "Подсумок"
                },
                15: {
                    "-2": "Фрагмент доспеха",
                    "-6": "Питомец",
                    "-7": "Летающий транспорт",
                    0: "Хлам",
                    1: "Реагент",
                    2: "Спутник",
                    3: "Праздник",
                    4: "Разное",
                    5: "Транспорт"
                },
                16: {
                    1: "Символ воина",
                    2: "Символ паладина",
                    3: "Символ охотника",
                    4: "Символ разбойника",
                    5: "Символ жреца",
                    6: "Символ рыцаря смерти",
                    7: "Символ шамана",
                    8: "Символ мага",
                    9: "Символ чернокнижника",
                    11: "Символ друида"
                }
            },
        }
    },
    methods: {
        refresh_instances: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/instance',
            }).then((response)=>{
                me.instances = response.data;
            });
        },
        refresh_bosses: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/instance/' + me.models.instance.select.id,
            }).then((response)=>{
                me.bosses = response.data;
            });
        },
        refresh_itemsets: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/boss/' + me.models.boss.select.id,
            }).then((response)=>{
                me.itemsets = response.data;
            });
        },
        refresh_items: async function(){
            const me = this;
            await axios.request({
                method: 'get',
                url: me.base_url + '/v1/itemset/' + me.models.itemset.select.id,
            }).then((response)=>{
                me.items = response.data;
            });
        },
        instance_create: async function(){
            const me = this;
            
            await axios.request({
                method: 'post',
                url: me.base_url + '/v1/instance/create',
                data: {
                    name: me.models.instance.create.name,
                },
            });
            me.models.instance.select = null;
            me.models.instance.create.name = null;
            await me.refresh_instances(); 
        },
        boss_create: async function(){
            const me = this;
            
            await axios.request({
                method: 'post',
                url: me.base_url + '/v1/boss/create',
                data: {
                    instance_id: me.models.instance.select.id,
                    name: me.models.boss.create.name,
                    priority: me.models.boss.create.priority,
                },
            });
            me.models.boss.select = null;
            me.models.boss.create.name = null;
            me.models.boss.create.priority = 1.0;
            await me.refresh_bosses();
        },
        itemset_create: async function(){
            const me = this;
            
            await axios.request({
                method: 'post',
                url: me.base_url + '/v1/itemset/create',
                data: {
                    boss_id: me.models.boss.select.id,
                    name: me.models.itemset.create.name,
                    chance: me.models.itemset.create.chance,
                },
            });
            me.models.itemset.select = null;
            me.models.itemset.create.name = null;
            me.models.itemset.create.chance = 100;
            await me.refresh_itemsets();
        },
        item_create: async function(){
            const me = this;
            var items = me.models.item.create.item_id.split(',');
            var noCacheItems = [];
            var cachedItems = [];

            await axios.request({
                method: 'post',
                url: me.base_url + '/v1/cache',
                data: {
                    items: items
                }
            }).then((response) => {
                noCacheItems = response.data;
            });

            cachedItems = items.diff(noCacheItems);

            if (noCacheItems.length) {
                for(var item of noCacheItems){ // 41592,45473,28295,28298
                    var result = null;

                    setTimeout(await axios.request({
                        method: 'get',
                        url: 'https://sirus.su/api/base/9/tooltip/item/'+item+'/',
                    }).then((response) => {
                        result = {
                            item_id: item,
                            json_data: response.data.item
                        };
                    }), 500);

                    await axios.request({
                        method: 'post',
                        url: me.base_url + '/v1/item/create',
                        data: {
                            itemset_id: me.models.itemset.select.id,
                            item_id: item,
                        }
                    });

                    await axios.request({
                        method: 'post',
                        url: me.base_url + '/v1/cache/create',
                        data: result
                    });
                    
                }
                
            }
            if (cachedItems.length) {
                for(var item of cachedItems){
                    await axios.request({
                        method: 'post',
                        url: me.base_url + '/v1/item/create',
                        data: {
                            itemset_id: me.models.itemset.select.id,
                            item_id: item,
                        }
                    });
                }
            }
            me.models.item.select = null;
            me.models.item.create.item_id = null;
            await me.refresh_items();
        },
        instance_update: async function(){
            const me = this;
            
            await axios.request({
                method: 'patch',
                url: me.base_url + '/v1/instance/update',
                data: {
                    id: me.models.instance.select.id,
                    name: me.models.instance.select.name,
                },
            });
            me.models.instance.select = null;
            me.bosses = null;
            me.itemsets = null;
            me.items = null;
            me.models.instance.create.name = null;
            await me.refresh_instances(); 
        },
        boss_update: async function(){
            const me = this;
            
            await axios.request({
                method: 'patch',
                url: me.base_url + '/v1/boss/update',
                data: {
                    id: me.models.boss.select.id,
                    name: me.models.boss.select.name,
                    priority: me.models.boss.select.priority,
                },
            });
            me.models.boss.select = null;
            me.itemsets = null;
            me.items = null;
            me.models.boss.create.name = null;
            await me.refresh_bosses(); 
        },
        itemset_update: async function(){
            const me = this;
            //console.log(me.models.itemset.select);
            await axios.request({
                method: 'patch',
                url: me.base_url + '/v1/itemset/update',
                data: {
                    id: me.models.itemset.select.id,
                    boss_id: me.models.itemset.select.boss_id,
                    name: me.models.itemset.select.name,
                    chance: me.models.itemset.select.chance,
                },
            });
            me.models.itemset.select = null;
            me.items = null;
            me.models.itemset.create.name = null;
            await me.refresh_itemsets(); 
        },
        blacklist: async function(event, item){
            event.preventDefault();
            const me = this;
            
            console.log(item);
            item.blacklisted = !item.blacklisted;
            await axios.request({
                method: 'patch',
                url: me.base_url + '/v1/item/update',
                data: {
                    id: item.base_id,
                    blacklisted: item.blacklisted
                },
            });
            await me.refresh_items();
            me.models.item.select = item;
        },
        instance_delete: async function(){
            const me = this,
            id = me.models.instance.select.id;
            await axios.request({
                method: 'delete',
                url: me.base_url + '/v1/instance/destroy/' + id,
            });
            await me.refresh_instances(); 
            me.bosses = null;
            me.itemsets = null;
            me.items = null;
            me.models.instance.select = null;
            me.models.boss.select = null;
            me.models.itemset.select = null;
            me.models.item.select = null;
        },
        boss_delete: async function(){
            const me = this,
            id = me.models.boss.select.id;
            await axios.request({
                method: 'delete',
                url: me.base_url + '/v1/boss/destroy/' + id,
            });
            await me.refresh_bosses(); 
            me.itemsets = null;
            me.items = null;
            me.models.boss.select = null;
            me.models.itemset.select = null;
            me.models.item.select = null;
        },
        itemset_delete: async function(){
            const me = this,
            id = me.models.itemset.select.id;
            await axios.request({
                method: 'delete',
                url: me.base_url + '/v1/itemset/destroy/' + id,
            });
            await me.refresh_itemsets(); 
            me.items = null;
            me.models.itemset.select = null;
            me.models.item.select = null;
        },
        item_delete: async function(){
            const me = this,
            id = me.models.item.select.base_id;
            await axios.request({
                method: 'delete',
                url: me.base_url + '/v1/item/destroy/' + id,
            });
            await me.refresh_items(); 
            me.models.item.select = null;
        },
        showTooltip(event, tooltipData) {
            console.log(event, tooltipData)
            return;
            this.tooltipData = tooltipData;
            this.tooltipX = event.pageX + 10; // Смещение тултипа относительно курсора
            this.tooltipY = event.pageY + 10; // Смещение тултипа относительно курсора
            this.showTooltip = true;
        },
        hideTooltip() {
            this.showTooltip = false;
        },
    },
    async created(){
        const me = this;
        await me.refresh_instances(); 

        /* el.addEventListener('mouseover', function(event) {
          vnode.context.showTooltip(event);
        });
        el.addEventListener('mouseleave', function(event) {
          vnode.context.hideTooltip();
        }); */
    },
    mounted(){
        /* const me = this,
        item = me.$refs.item;
        tooltip.addEventListener('mouseover', function(event) {
          vnode.context.showTooltip(event);
        });
        tooltip.addEventListener('mouseleave', function(event) {
          vnode.context.hideTooltip();
        }); */
    }
}
document.addEventListener('contextmenu', event => event.preventDefault());
</script>

<style lang="scss">
h2 {
    text-align: center;
}
.flex {
	width: 100%;
	height:calc(100vh - 24px);
	display: -webkit-flex; 
	-webkit-flex-direction: row;
	display: flex;
	flex-direction: row;
    
	background-image: url(@/assets/background.png);
	background-size: cover;
}
.btn-warcraft {
    background-color: rgb(107,0,0);
    border: 2px outset rgb(100,100,100);
    color: rgb(218,165,32) !important;
    &[disabled] {
        background-color: rgb(110,110,110);
    }
    &:hover{
        background-color: rgb(115,0,0);
        color: rgb(228,175,42);
    }
}
.col-xs-3{
    width: 25%;
}
.h100 {
	display: -webkit-flex; 
	-webkit-flex-direction: column;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border: 16px solid rgba(0,0,0,0);
	border-image: url(@/assets/frame.png) 16 round;
}
.item-list {
	display: -webkit-flex; 
	-webkit-flex-direction: column;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.item-list ul {
	flex-grow: 3;
	flex-shrink: 1;
	border: 1px solid rgba(0,0,0,0.5);
	background-color: /*rgba(54,32,19,1);*/rgba(0,0,0,0.25);
	overflow-y: scroll;
	overflow-x: hidden;
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 8px;
}
.item-list ul li {
	height: 36px;
	line-height: 36px;
	font-size: 16px;
	white-space: nowrap;
	padding: 0 8px;
	border-bottom: 1px solid rgba(0,0,0,0.5);
    color: white;
	text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
	cursor: pointer;

}
.item-list ul li:hover {
	background-color: rgba(0,0,0,0.2);
}
.item-list ul li:last-child {
	border-bottom: none;
}

/* Item quality colors */
.q  { color: #ffd100 }
.q0, .q0 a { color: #9d9d9d }
.q1, .q1 a { color: #ffffff }
.q2, .q2 a, .q2:hover { color: #1eff00 }
.q3, .q3 a { color: #0070dd }
.q4, .q4 a { color: #a335ee }
.q5, .q5 a { color: #ff8000 }
.q6, .q6 a { color: #e5cc80 }
.q7, .q7 a { color: #e5cc80 }
.q8, .q8 a { color: #ffff98 }
.q9, .q9 a { color: #71D5FF }
.q10, .q10 a { color: #ffd100 }
.q11, .q11 a { color: #ff4040 }

.c1,.c1 a { color: #c69b6d !important }
.c2,.c2 a { color: #f48cba !important }
.c3,.c3 a { color: #aad372 !important }
.c4,.c4 a { color: #fff468 !important; text-shadow: #000 1px 1px; }
.c5,.c5 a { color: #fff !important; text-shadow: 1px 1px 1px black; }
.c6,.c6 a { color: #c41e3b !important }
.c7,.c7 a { color: #2359ff !important }
.c8,.c8 a { color: #68ccef !important }
.c9,.c9 a { color: #9382c9 !important }
.c10,.c10 a { color: #008467 !important }
.c11,.c11 a { color: #ff7c0a !important }
.c12,.c12 a { color: #a330c9 !important }

.s-wow-tooltip[data-type="npc"],
.s-wow-tooltip[data-type="quest"] {
    color: #ffd100;
}

.s-wow-tooltip[data-type="achievement"],
.s-wow-tooltip[data-type="npc"],
.s-wow-tooltip[data-type="quest"] {
    text-shadow: #000 1px 1px;
}

/* Sockets */
.socket-slot {
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 23px;
}
.socket-meta   { background-image: url(@/assets/armory/socket_meta.gif) }
.socket-red    { background-image: url(@/assets/armory/socket_red.gif) }
.socket-yellow { background-image: url(@/assets/armory/socket_yellow.gif) }
.socket-blue   { background-image: url(@/assets/armory/socket_blue.gif) }
.socket-prismatic { background-image: url(@/assets/armory/socket-prismatic.gif) }
.socket-hydraulic { background-image: url(@/assets/armory/socket-hydraulic.gif) }
.socket-cogwheel { background-image: url(@/assets/armory/socket-cogwheel.gif) }
/* Tooltip */
#s-wow-tooltip.active {
    padding: 5px 7px;
    background-color:#070c21;
    background-color: rgba(7,12,33,0.9);
    border-radius: 3px;
    border:1px solid #777777;
    border-top:1px solid #cfcfcf;
    -webkit-box-shadow: 0px 0px 3px #000;
    -moz-box-shadow: 0px 0px 3px #000;
    box-shadow: 0px 0px 3px #000;
    color: #fff;
    font-size: 12px;
    line-height:1.5;
    /*display: none;*/
    /*position: absolute;
    z-index: 15000;*/
}

@media (max-width: 400px)
{
    .s-wow-tooltip-body
    {
        width: 265px;
    }
}

.s-wow-tooltip-body
{
    width: 330px;
}

.s-wow-tooltip-body.s-wow-tooltip-body-quest
{
    width: unset;
    min-width: 330px;
}

.s-wow-tooltip-body .icon {
    width: 45px;
    position: absolute;
    left: -50px;
    top: -1px;
}

.s-wow-tooltip .icon {
    width: 18px;
    vertical-align: middle;
}

.s-wow-tooltip-spell .s-wow-tooltip--rank {
    float: right;
    color: #d3d3d3;
    font-size: 15px;
}
.s-wow-tooltip--title {
    font-size: 16px;
    float: left;
    margin-bottom: 5px;
}
.s-wow-tooltip-body-quest .w-100 {
    width:100%;
}

.s-wow-tooltip--points {
    font-size: 80%;
}

.item-set-piece {
    padding-left: 10px;
}

.s-wow-tooltip.q1, .s-wow-tooltip.q1 a {
    text-shadow: 1px 1px 0.05rem black;
}

.s-wow-tooltip--title .s-wow-tooltip--points {
    float: right;
    margin-top: 2px;
}

.s-wow-tooltip-guild .s-wow-tooltip--points {
    float: right;
    margin-top: 2px;
    font-size: 110%;
}

.s-wow-tooltip-clear {
    clear: both;
}

.list-unstyled
{
    list-style-type: none;
    margin-block-start: 0;
    margin-inline-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
}

.s-wow-tooltip-body ul {
    list-style-type: none;
    padding-left: 5px;
}

.s-wow-tooltip-body.s-wow-tooltip-body-quest ul {
    list-style-type: none;
    padding-left: 0px;
}

.s-wow-tooltip-body ul > li:before {
    content: "-";
    text-indent: -5px;
}
.moneygold {
    padding-left: 14px;
    background: url(@/assets/icons/money-gold.gif) left center no-repeat;
}

.moneysilver {
    padding-left: 14px;
    background: url(@/assets/icons/money-silver.gif) left center no-repeat;
}

.moneycopper {
    padding-left: 14px;
    background: url(@/assets/icons/money-copper.gif) left center no-repeat;
}

.icon-horde {
    padding-left: 18px;
    background: url(@/assets/icons/horde.gif) left center no-repeat;
}

.icon-alliance {
    padding-left: 12px;
    background: url(@/assets/icons/alliance.gif) left center no-repeat;
}

.icon-renegade {
    padding-left: 14px;
    background: url(@/assets/icons/renegade.gif) left center no-repeat;
}

.col-xs-4 {
  width: 33.33333333333333%;
}
</style>
