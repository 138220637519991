<template>
  <v-container>
    <v-container v-if="!register">
      <v-card>
        <v-card-title>Авторизация</v-card-title>
        <v-card-item>
          <v-alert v-if="models.error.recorded" :icon="models.error.success ? '$success': '$error'" :title="models.error.success ? 'Успешно': 'Ошибка'" :text="models.error.message">
          </v-alert><br>
          <v-text-field label="E-Mail" :rules="rules.auth.email" v-model="models.auth.email" />
          <v-text-field label="Пароль" type="password" :rules="rules.auth.password" v-model="models.auth.password" />

          <v-checkbox label="Запомнить?" v-model="models.auth.remember"></v-checkbox>
          <v-btn @click="auth()">Войти</v-btn> 
        </v-card-item>
      </v-card>
    </v-container>
    <v-container v-else>
      <v-card>
        <v-card-title>Регистрация</v-card-title>
        <v-card-item>

          <v-text-field label="E-Mail" :rules="rules.register.email" v-model="models.register.email" />
          <v-text-field label="Имя пользователя" :rules="rules.register.name" v-model="models.register.name" />
          <v-text-field label="Пароль" type="password" :rules="rules.register.password" v-model="models.register.password" />
          <v-text-field label="Повторите пароль" type="password" :rules="rules.register.confirm_password" v-model="models.register.confirm_password" />
          <v-btn @click="doregister()">Зарегистрироваться</v-btn> 
        </v-card-item>
      </v-card>
    </v-container>
    <template v-if="!register">
      <v-btn @click="changeMode">У меня нет аккаунта</v-btn>
    </template>
    <template v-else>
      <v-btn @click="changeMode">У меня уже есть аккаунт</v-btn>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Auth',

  data: () => ({
    register: false,
    rules: {
      auth: {
        email: [
          v => !!v || 'E-Mail обязателен к заполнению',
          v => /([a-zA-Z0-9\-_.]+)@([a-zA-Z0-9\-_.]+).([a-zA-Z0-9\-_.]+)/.test(v) || 'Образец: example@domain.com',
        ],
        password: [
          v => !!v || 'Пароль обязателен к заполнению',
          v => v.length >= 8 || 'Пароль должен быть не менее 8 символов',
        ],
      },
      register: {
        email: [
          v => !!v || 'E-Mail обязателен к заполнению',
          v => /([a-zA-Z0-9\-_.]+)@([a-zA-Z0-9\-_.]+).([a-zA-Z0-9\-_.]+)/.test(v) || 'Образец: example@domain.com',
        ],
        name: [
          v => !!v || 'Имя пользователя обязателено к заполнению',
          v => /([a-zA-Z0-9\-_ ]*)/.test(v) || 'Образец: Ivan Ivanov',
        ],
        password: [
          v => !!v || 'Пароль обязателен к заполнению',
          v => v.length >= 8 || 'Пароль должен быть не менее 8 символов',
        ],
        confirm_password: [
          v => !!v || 'Повторение пароля обязателено',
          
        ],
      }
    },
    models: {
      auth: {
        email: null,
        password: null,
        remember: false,
      },
      register:{
        email: null,
        name: null,
        password: null,
        confirm_password: null,
      },
      error: {
        recorded: null,
        success: null,
        message: null,
      },
    }
  }),
  methods: {
    changeMode: function(){
      const me = this;
      me.models = {
        auth: {
          email: null,
          password: null,
          remember: false,
        },
        register:{
          email: null,
          name: null,
          password: null,
          confirm_password: null,
        },
        error: {
          recorded: null,
          success: null,
          message: null,
        },
      }
      me.register = !me.register;
    },
    auth: async function(){
      const me = this,
      model = me.models.auth;

      await axios.request({
        method: 'post',
        url: me.base_url + '/login',
        data: model
      }).then((response)=>{
        if(response.status == 204){
          me.models.error = {
            recorded: true,
            success: true,
            message: "Вы успешно авторизовались"
          }
          location.reload();
          return;
        }
        
      }).catch((reason) => {
        console.log(reason.response.data);
        me.models.error = {
          recorded: true,
          success: false,
          message: reason.response.data.error.message
        };
      });

      //console.log(result);
    },
    doregister: async function (){
      const me = this,
      model = me.models.register;

      await axios.request({
        method: 'post',
        url: me.base_url + '/register',
        data: model
      }).then((response)=>{
        if(response.status == 204){
          me.models.error = {
            recorded: true,
            success: true,
            message: "Вы успешно авторизовались"
          }
          location.reload();
          return;
        }
        
      }).catch((reason) => {
        debugger;
        me.models.error = {
          recorded: true,
          success: false,
          message: reason.message 
        };
      });
    }
  }
}
</script>
