<template>
    {{ test }}
    <v-file-input accept="application/JSON" label="Файл импорта (JSON)" @change="handleFileChange" prepend-icon="mdi-code-json"></v-file-input>
    Обнаружено
    <table>
        <tbody>
            <tr><td>Подземелий</td><td>{{counter.instances}}</td></tr>
            <tr><td>Боссов</td><td>{{counter.bosses}}</td></tr>
            <tr><td>Групп предметов</td><td>{{counter.itemsets}}</td></tr>
            <tr><td>Предметов</td><td>{{counter.items}}</td></tr>
        </tbody>
    </table>
    <v-btn :disabled="!jsonData" @click="importData">Импортировать</v-btn><br>
    <v-card>
        <v-alert>
            <v-alert-title>Статус</v-alert-title>
            <span v-if="message">
                {{ message }}
            </span>
        </v-alert>
        <v-card-text>{{current}}</v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import axios from 'axios'

export default {
    data(){
        return {
            test: 'test',
            jsonData: null,
            current: {
                instance: null,
                boss: null,
                itemset: null,
                item: null,
            },
            message: null
        }
    },
    computed: {
        counter: function () {
            const me = this,
            instances = me.jsonData;
            const counter = {
                instances: 0,
                bosses: 0,
                itemsets: 0,
                items: 0,
            };
            if(!instances) return counter;
            counter.instances = instances.length; 
            for (const instance of instances) {
                const bosses = instance.Bosses;
                counter.bosses += bosses.length;
                for (const boss of bosses) {
                    const itemsets = boss.Itemsets;
                    counter.itemsets += itemsets.length;
                    for (const itemset of itemsets) {
                        const items = itemset.Items;
                        counter.items += items.length;
                    }
                }
            }
            return counter;
        }
    },
    methods: {
        handleFileChange(event) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const jsonData = JSON.parse(e.target.result);
                    this.jsonData = jsonData;
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            };

            reader.readAsText(file);
        },
        importData: async function(){
            const me = this;
            const instances = me.jsonData;
            me.message = "Начинаю...";
            var count = 0;
            var max_count = me.counter.bosses+me.counter.instances+me.counter.itemsets+me.counter.items;
            for (const instance of instances) {
                var last_instance = null;
                
                await axios.request({
                    method: 'post',
                    url: me.base_url + '/v1/instance/create',
                    data: {
                        name: instance.Name,
                    }
                });
                await axios.request({
                    method: 'get',
                    url: me.base_url + '/v1/instance/last',
                }).then(response => {
                    last_instance = response.data;
                });
                count++;
                me.message = `Прогресс: ${(count / max_count) * 100} %`;
                if (last_instance) {
                    for (const boss of instance.Bosses) {
                        var last_boss = null;
                        await axios.request({
                            method: 'post',
                            url: me.base_url + '/v1/boss/create',
                            data: {
                                instance_id: last_instance.id,
                                name: boss.Name,
                            }
                        });
                        await axios.request({
                            method: 'get',
                            url: me.base_url + '/v1/boss/last',
                        }).then(response => {
                            last_boss = response.data;
                        });
                        count++;
                        me.message = `Прогресс: ${(count / max_count) * 100} %`;
                        if (last_boss) {
                            for (const itemset of boss.Itemsets) {
                                var last_itemset = null;
                                await axios.request({
                                    method: 'post',
                                    url: me.base_url + '/v1/itemset/create',
                                    data: {
                                        boss_id: last_boss.id,
                                        name: itemset.Name,
                                        chance: itemset.Chance * 100,
                                    }
                                });
                                await axios.request({
                                    method: 'get',
                                    url: me.base_url + '/v1/itemset/last',
                                }).then(response => {
                                    last_itemset = response.data;
                                });
                                count++;
                                me.message = `Прогресс: ${(count / max_count) * 100} %`;
                                if(last_itemset) {
                                    //itemset.Items
                                    me.current.instance = last_instance.name;
                                    me.current.boss = last_boss.name;
                                    me.current.itemset = last_itemset.name;
                                    var items = [];
                                    for (const item of itemset.Items) {
                                        items.push(item.ItemID);
                                    }
                                    var noCacheItems = [];
                                    var cachedItems = [];

                                    await axios.request({
                                        method: 'post',
                                        url: me.base_url + '/v1/cache',
                                        data: {
                                            items: items
                                        }
                                    }).then((response) => {
                                        noCacheItems = response.data;
                                    });

                                    cachedItems = items.diff(noCacheItems);

                                    if (noCacheItems.length) {
                                        for(var item of noCacheItems){ // 41592,45473,28295,28298
                                            var result = null;
                                            me.current.item = item;
                                            setTimeout(await axios.request({
                                                method: 'get',
                                                url: 'https://sirus.su/api/base/9/tooltip/item/'+item+'/',
                                            }).then((response) => {
                                                result = {
                                                    item_id: item,
                                                    json_data: response.data.item
                                                };
                                            }), 500);

                                            await axios.request({
                                                method: 'post',
                                                url: me.base_url + '/v1/item/create',
                                                data: {
                                                    itemset_id: last_itemset.id,
                                                    item_id: item,
                                                }
                                            });

                                            await axios.request({
                                                method: 'post',
                                                url: me.base_url + '/v1/cache/create',
                                                data: result
                                            });
                                            
                                            count++;
                                            me.message = `Прогресс: ${(count / max_count) * 100} %`;
                                        }
                                        
                                    }
                                    if (cachedItems.length) {
                                        for(var item of cachedItems){
                                            await axios.request({
                                                method: 'post',
                                                url: me.base_url + '/v1/item/create',
                                                data: {
                                                    itemset_id: last_itemset.id,
                                                    item_id: item,
                                                }
                                            });
                                            count++;
                                            me.message = `Прогресс: ${(count / max_count) * 100} %`;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            me.message = `Успешно импортировано ${max_count} записей`;
        }
    }
}
</script>