import axios from "axios";

const auth = {
    authorize(credentials){
        const url = '/api/login';

        // Отправляем POST-запрос на URL для входа пользователя с переданными учетными данными
        return axios.post(url, credentials)
            .then(response => {
                // Обработка успешного ответа
                console.log('Успешная аутентификация', response.data);
                // Возвращаем данные ответа
                return response.data;
            })
            .catch(error => {
                // Обработка ошибки
                console.error('Ошибка аутентификации', error.response.data);
                // Возвращаем ошибку
                throw error.response.data;
            });
    },
    async check(){
        const url = '/api/user';
        var result = null;
        // Отправляем GET-запрос на URL для проверки статуса аутентификации
        await axios.get(url)
            .then(response => {
                // Обработка успешного ответа
                //console.log('Проверка аутентификации прошла успешно', response.data);
                // Возвращаем данные ответа
                result = {success: true, data: response.data};
            })
            .catch(error => {
                // Обработка ошибки
                //console.error('Ошибка проверки аутентификации', error.response.data);
                // Возвращаем ошибку
                result = {success: false, data: error.response.data};
            })
        return result;
    }
}

export { auth };