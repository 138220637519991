import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

loadFonts()

Array.prototype.diff = function(arr2) { 
  return this.filter(x => !arr2.includes(x)); 
}

const globals = {
  data:()=>({
    base_url: '/api'
  }),
  methods: {
    formatString(string, params) {
      return string.replace(/{(\d+)}/g, (match, index) => {
        return typeof params[index] !== 'undefined' ? params[index] : match;
      });
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },
    isCookieExpired(name) {
      const cookieValue = this.getCookie(name); // Предполагается, что у вас уже есть функция getCookie
  
      if (cookieValue) {
          const cookieData = decodeURIComponent(cookieValue);
          const cookieParts = cookieData.split('; ');
  
          // Поиск среди частей cookie информации об истечении срока его действия
          for (let i = 0; i < cookieParts.length; i++) {
              const part = cookieParts[i];
              if (part.indexOf('expires=') === 0) {
                  const expires = new Date(part.substring(8)).getTime();
                  const now = new Date().getTime();
                  return expires < now;
              }
              // Если вместо 'expires=' используется 'max-age=',
              // следует провести аналогичную проверку
              // Здесь предполагается, что используется 'expires='
          }
  
          // Если информации о сроке действия нет, считаем cookie действующим
          return false;
      }
  
      // Если cookie не найден, считаем его истекшим
      return true;
    }
  }
};

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mixin(globals)
  .mount('#app')
